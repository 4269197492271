export const featured2024 = [
  {
    Name: "Make Your Own Project",
    Description: `Make Your Own Open Source Project is an opportunity for individuals or groups to create and contribute to an open source project during the Social Winter of Code Season 3. This program is designed to support and encourage the development of innovative and impactful open source projects that address social and humanitarian issues.

        Participants will have the opportunity to work with experienced mentors and a community of like-minded individuals to develop their project ideas and bring them to fruition. Throughout the program, participants will have access to resources and support to help them develop their skills and knowledge as open source contributors.
        
        The Make Your Own Open Source Project program is open to individuals of all skill levels, from beginners to experienced open source contributors. Whether you are a developer, designer, researcher, or have another area of expertise, there is a place for you in this program.
        
        The Social Winter of Code Season 3 is a great opportunity to make a positive impact on the world through the development of open source projects. We encourage you to apply and become a part of this exciting community of changemakers.`,
    Tag: `feature`,
    TechStack: ["Tech Stack of Your Choice"],
    Owner: "You will be the owner of the repository",
  },
];

export const projectsData2024 = [
  {
    Name: "DL-Simplified",
    Repo: "https://github.com/abhisheks008/DL-Simplified",
    Owner: "Abhishek Sharma",
    github: "https://github.com/abhisheks008",
    linkedin: "https://www.linkedin.com/in/abhishek-sharma-aa06a9183/",
    Description: `Deep Learning Simplified is an Open-source repository, containing beginner to advance level deep learning projects for the contributors, who are willing to start their journey in Deep Learning.`,
    TechStack: [
      "Deep Learning",
      "Machine Learning",
      "Artificial Intelligence",
      "Data Analysis",
      "Keras",
      "Tensorflow",
      "Neural Networks",
      "NLP",
      "Image Processing",
      "Computer Vision",
    ],
  },
  {
    Name: "Resonate",
    Repo: "https://github.com/AOSSIE-Org/Resonate",
    Owner: "Chandan S Gowda",
    github: "https://github.com/chandansgowda",
    linkedin: "https://www.linkedin.com/in/chandan-s-gowda-4b2913183/",
    Description: `With the rising popularity of social voice platforms such as Clubhouse and Twitter Spaces, it is high time for an Open Source alternative. A platform like this would not only enhance credibility within the open-source community but also attract more users and foster growth. An engagement platform that is Open Source has the potential to drive significant traction and help establish a strong presence.`,
    TechStack: ["flutter", "nodejs", "appwrite", "livekit"],
  },
  {
    Name: "EuphoriaCheck",
    Repo: "https://github.com/Aryainguz/Euphoria-Check-PERMA-Meter-Express",
    Owner: "Aryan Inguz",
    github: "https://github.com/Aryainguz",
    linkedin: "https://www.linkedin.com/in/aryan-inguz-b73150254/",
    Description: `A powerful tool to help you assess and improve your well-being! This web application, built using Express and Node.js, measures your happiness and life satisfaction based on the PERMA model of well-being by psychologist Dr Martin Seligman. With personalized insights, users gain a comprehensive breakdown of their PERMA scores, identifying areas for personal growth and well-being enhancement.`,
    TechStack: ["nodejs", "expressjs", "ejs", "rest APIs (javascript)"],
  },
  {
    Name: "Minglr - Social Networking Website",
    Repo: "https://github.com/Mayuresh-22/Minglr",
    Owner: "Mayuresh Choudhary",
    github: "https://github.com/Mayuresh-22",
    linkedin: "https://www.linkedin.com/in/mayureshchoudhary",
    Description: `Minglr is a secure and feature-rich social networking site that enables users to connect, interact, and share content with others. Built with PHP for the backend and HTML5, CSS, and JavaScript for the front end, Minglr provides a seamless and enjoyable user experience. Contributions to Minglr are welcome!`,
    TechStack: [
      "PHP",
      "HTML5",
      "CSS",
      "javascript",
      "MySql",
      "DiceBear HTTP API",
    ],
  },
  {
    Name: "Demon Connect",
    Repo: "https://github.com/anupammaurya6767/Demon_connect",
    Owner: "Anupam Maurya",
    github: "https://github.com/anupammaurya6767",
    linkedin:
      "https://www.linkedin.com/in/anupam-maurya-b9a04a225?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    Description: `Welcome to Demon Connect - WhatsApp API, your powerful tool for unleashing the potential of WhatsApp in your applications. This API allows you to integrate WhatsApp messaging into your projects with ease.`,
    TechStack: ["python", "selenium"],
  },
  {
    Name: "digitomize",
    Repo: "https://github.com/digitomize/digitomize",
    Owner: "Pranshu Gupta",
    github: "https://github.com/pranshugupta54",
    linkedin: "https://www.linkedin.com/in/pranshu54/",
    Description: `Platform for exploring coding contests and creating dynamic portfolios that showcase their coding skills and achievements.`,
    TechStack: [
      "reactjs",
      "vite",
      "tailwind",
      "nodejs",
      "expressjs",
      "npm",
      "mongoDB",
      "firebase",
    ],
  },
  {
    Name: "Virtuo Learn",
    Repo: "https://github.com/JAYESHBATRA/Virtuo-Learn",
    Owner: "Jayesh Batra",
    github: "https://github.com/JAYESHBATRA",
    linkedin: "https://www.linkedin.com/in/jayesh-batra-in/",
    Description: `Virtuo Learn is a platform that provides immersive learning experiences like never before. It offers simulations, 3D visualizations, customized quizzes, doubt engine, and videos, all in one place.`,
    TechStack: ["html", "css", "javascript", "matterjs", "threejs"],
  },
  {
    Name: "stabo",
    Repo: "https://github.com/Arceuuss/stabo",
    Owner: "RATUL ADHIKARY",
    github: "https://github.com/ratuladhikary21",
    linkedin: "https://www.linkedin.com/in/ratul-adhikary-829716240/",
    Description: `Ai Image Generator App`,
    TechStack: [
      "java",
      "typescript",
      "objective-c",
      "ruby",
      "javascript",
      "objective-c++",
    ],
  },
  {
    Name: "MindSpace",
    Repo: "https://github.com/The-MindSpace/MindSpace-Web",
    Owner: "Subroto Banerjee",
    github: "https://github.com/TeeWrath",
    linkedin: "https://www.linkedin.com/in/subroto-banerjee-70983b214/",
    Description: `MindSpace is a web app that aims to improve access to mental health resources and support, particularly for those who may not have the means or ability to attend in-person appointments. The app includes functionality for mental health resources such as support groups, therapy options, and crisis hotlines.`,
    TechStack: ["html", "css", "javascript"],
  },
  {
    Name: "GenAI-Projects",
    Repo: "https://github.com/yagyesh-bobde/GenAI-Projects",
    Owner: "Yagyesh Bobde",
    github: "https://github.com/yagyesh-bobde",
    linkedin: "https://www.linkedin.com/in/bobde-yagyesh/",
    Description: `A wide curration of open-source projects and applications in the emerging field of generative AI. It will also be a curation of resources to start learning about generative AI.`,
    TechStack: [
      "html",
      "javascript",
      "python",
      "tensorflow",
      "pytorch",
      "flask",
      "reactjs",
      "nodejs",
      "APIs",
    ],
  },
  {
    Name: "GPT-3.5-ON-STEROIDS",
    Repo: "https://github.com/programmingninjas/GPT-3.5-ON-STEROIDS",
    Owner: "Ayan Khan",
    github: "https://github.com/programmingninjas",
    linkedin: "https://www.linkedin.com/in/ayankhan3105/",
    Description: `GPT-3.5-ON-STEROIDS combines GPT with Python tools, empowering dynamic web scraping, language processing, and data retrieval. Contribute to advancing text generation with AI. 🚀`,
    TechStack: ["openAI", "python", "bs4", "streamlit"],
  },
  {
    Name: "MealMatters",
    Repo: "https://github.com/AnupK1234/MealMatters",
    Owner: "Anup Khismatrao",
    github: "https://github.com/AnupK1234",
    linkedin: "https://www.linkedin.com/in/anup-khismatrao-729520221/",
    Description: `MealMatters is a webapp designed to overcome student food insecurity by connecting students with nearby restaurants that provide free meals. The webapp facilitates student registration, enabling them to view a list of nearby restaurants offering free meals and request coupons from the organization. Donors can also register for an account and make donations to the organization, which utilizes these funds to reimburse restaurants for meals provided to students. MealMatters aims to increase student access to free meals, alleviate the financial burden of food insecurity, raise awareness of the issue, and encourage support for student food insecurity initiatives.`,
    TechStack: [
      "reactjs",
      "nodejs",
      "expressjs",
      "mongoDB",
      "tailwindCSS",
      "javascript",
    ],
  },
  {
    Name: "Brighter-Beginnings ",
    Repo: "https://github.com/dakshsinghrathore/Brighter-Beginnings",
    Owner: "Daksh Singh Rathore",
    github: "https://github.com/dakshsinghrathore",
    linkedin:
      "https://www.linkedin.com/in/daksh-singh-rathore-%F0%9F%87%AE%F0%9F%87%B3-84aa981aa?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    Description: `Discover coding for beginners in our GitHub repo! Enjoy a free three-module course, an interactive learning experience, and a supportive community. Your journey to a brighter future starts now! Join us!`,
    TechStack: ["html", "tailwindCSS", "javascript", "firebase"],
  },
  {
    Name: "School Management System EMS",
    Repo: "http://github.com/iHRex/school-management",
    Owner: "HARSH VERMA",
    github: "http://github.com/ihrex",
    linkedin: "http://linkedin.com/in/ihrex",
    Description: `A one stop, free and open sourced solution for educational institutions.
School or College Management System (ERP) wordpress based plugin. Supports features such as- Admission, Fee Management, ID Card Generator, mPDF, Attendance etc.`,
    TechStack: ["PHP", "javascript", "CSS", "wordpress"],
  },
  {
    Name: "Python gamez arena",
    Repo: "https://github.com/pinak22dhir/Python-Gamez-Arena",
    Owner: "Pinak Dhir",
    github: "https://github.com/pinak22dhir",
    linkedin: "https://www.linkedin.com/in/pinak-dhir-b03621265",
    Description: `Python game`,
    TechStack: ["python"],
  },
  {
    Name: "COMPLETE-Fullstack-ecommerce-Responsive-MERN-App ",
    Repo: "https://github.com/Souvikparua/COMPLETE-Fullstack-ecommerce-Responsive-MERN-App",
    Owner: "Souvik Parua",
    github: "https://github.com/Souvikparua",
    linkedin: "https://www.linkedin.com/in/souvik-parua-bb031a230/",
    Description: `A COMPLETE Fullstack ecommerce Responsive MERN App using React, Redux, Nodejs, and MongoDB. A working ecommerce site from scratch, using React, Redux, Nodejs, and MongoDB.`,
    TechStack: ["reactjs", "redux", "nodejs", "mongoDB"],
  },
  {
    Name: "Palettegram",
    Repo: "https://github.com/Sanchitbajaj02/palettegram",
    Owner: "Sanchit Bajaj",
    github: "https://github.com/Sanchitbajaj02",
    linkedin: "https://www.linkedin.com/in/sanchitbajaj02",
    Description: `Palettegram is a social media application dedicated to professionals like graphic designers, UI/UX designers, Developers, etc. to leverage UI designs, design ideas, UX approaches and color palettes.`,
    TechStack: ["typescript", "nextjs", "tailwindCSS", "appwrite"],
  },
  {
    Name: "SQL Dev Tools",
    Repo: "https://github.com/PranabKumarSahoo/sql-dev-tools",
    Owner: "Pranab Kumar Sahoo",
    github: "https://github.com/PranabKumarSahoo",
    linkedin: "https://www.linkedin.com/in/pranabks/",
    Description: `So basically, this is a tools for SQL Developers, a React-based application empowering SQL developers with versatile functionalities and make their daily tasks easy.`,
    TechStack: ["reactjs", "javascript", "css", "firebase"],
  },
  {
    Name: "Click-The-Edible-Game",
    Repo: "https://github.com/Rakesh9100/Click-The-Edible-Game",
    Owner: "Rakesh Roshan",
    github: "https://github.com/Rakesh9100",
    linkedin: "https://www.linkedin.com/in/rakesh-roshan-9100/",
    Description: `A well-designed interesting game made using HTML, CSS, and JS where you can play with your favourite edible and click it to get the most scores.`,
    TechStack: ["html", "css", "javascript", "jQuery"],
  },
  {
    Name: "Beautiify",
    Repo: "https://github.com/Rakesh9100/Beautiify",
    Owner: "Rakesh Roshan",
    github: "https://github.com/Rakesh9100",
    linkedin: "https://www.linkedin.com/in/rakesh-roshan-9100/",
    Description: `Beautiify comprises a curated selection of beautifully designed components and animations that can be seamlessly incorporated into any website, elevating its overall UI experience.`,
    TechStack: ["html", "css", "javascript", "jQuery"],
  },
  {
    Name: "CalcDiverse",
    Repo: "https://github.com/Rakesh9100/CalcDiverse",
    Owner: "Rakesh Roshan",
    github: "https://github.com/Rakesh9100",
    linkedin: "https://www.linkedin.com/in/rakesh-roshan-9100/",
    Description: `CalcDiverse is a customized collection of calculators for various aspects of mathematics. Individuals with basic web development knowledge can create distinctive calculators and submit pull requests.`,
    TechStack: ["html", "css", "javascript", "jQuery"],
  },
  {
    Name: "FlavorFleet",
    Repo: "https://github.com/sagnik3788/FlavorFleet",
    Owner: "sagnik das",
    github: "https://github.com/sagnik3788/",
    linkedin: "https://www.linkedin.com/in/sagnik-das-b550ab224/",
    Description: `Initiated and contributed to the development of FlavorFleet, an open-source food website built with Node.js, and MongoDB. Designed and implemented features showcasing international foods, leveraging the skills of various cooks within the community. Implemented a user-friendly cart facility for users to easily select and order their favorite dishes.`,
    TechStack: ["html", "css", "javascript", "nodejs"],
  },
  {
    Name: "Flutter Event App",
    Repo: "https://github.com/LegendSumeet/eventapp",
    Owner: "Sumeet Vishwakarma ",
    github: "https://www.github.com/LegendSumeet",
    linkedin: "https://www.linkedin.com/in/sumeetvishwakarma/",
    Description: `An open-source Flutter-based Event Planning App to simplify the process of organizing and managing various types of events.`,
    TechStack: ["flutter", "firebase"],
  },
  {
    Name: "GameSphere3.0",
    Repo: "https://github.com/GameSphere-MultiPlayer/GameSphere3.0",
    Owner: "Aman Kumar Singh ",
    github: "https://github.com/Aman1-coder",
    linkedin: "https://www.linkedin.com/in/aman-singh-026141222/",
    Description: `Our website's goal is to create a website where two or more friends can gather and play games online (such as multiplayer) without having to download any software or tools to their computers or mobile devices.`,
    TechStack: [
      "html",
      "css",
      "javascript",
      "bootstrap",
      "jQuery",
      "PHP",
      "laravel",
      "MySQl",
    ],
  },
  {
    Name: "GeetaGPT",
    Repo: "https://github.com/subh05sus/GeetaGPT",
    Owner: "Subhadip Saha",
    github: "https://github.com/subh05sus",
    linkedin: "https://www.linkedin.com/in/subhadipsahaofficial",
    Description: `GeetaGPT is a chatbot built with Flask that embodies the persona of Lord Krishna, possessing extensive knowledge from Shrimadvagwat Geeta. The bot is powered by the OpenAI GPT-3.5 model, capable of answering life problems, providing advice, and offering motivation.`,
    TechStack: ["python", "flask", "html", "css", "javascript"],
  },
  {
    Name: "BlockRush Game",
    Repo: "https://github.com/subh05sus/BlockRush-Game",
    Owner: "Subhadip Saha",
    github: "https://github.com/subh05sus/",
    linkedin: "https://www.linkedin.com/in/subhadipsahaofficial",
    Description: `Block Rush is an exciting endless game where players control a nimble block, dodging obstacles and dashing through an ever-changing course. This repository contains the source code for the game, providing an open-source platform for collaboration, improvement, and community contribution.`,
    TechStack: ["unity", "blender", "C#"],
  },
  {
    Name: "Python Voice Assistant",
    Repo: "https://github.com/subh05sus/Python-Voice-Assistant",
    Owner: "Subhadip Saha",
    github: "https://github.com/subh05sus/",
    linkedin: "https://linkedin.com/subhadipsahaofficial",
    Description: `A Python-based voice assistant that can greet you and perform a variety of tasks, including searching the web, opening social media, and creating a to-do list and much more to play with the amazing features.`,
    TechStack: ["python"],
  },
  {
    Name: "Thala For Reason",
    Repo: "https://github.com/subh05sus/ThalaForReason",
    Owner: "Subhadip Saha",
    github: "https://github.com/subh05sus/",
    linkedin: "https://linkedin.com/subhadipsahaofficial",
    Description: `Thala For Reason is a fun project inspired by the legendary MS Dhoni, also known as "Thala." The project allows users to check if they are Thala in a humorous way, following the famous meme trend.`,
    TechStack: [
      "nodejs",
      "expressjs",
      "html",
      "css",
      "javascript",
      "gemini pro",
    ],
  },
  {
    Name: "ecosim-client",
    Repo: "https://github.com/ai-economy-simulator/ecosim-client",
    Owner: "Amish Mittal",
    github: "https://github.com/fliptrail",
    linkedin: "https://www.linkedin.com/in/amishmittal/",
    Description: `Client app for AI Economy Simulator. Using AI program synthesis to predict macroeconomic factors in the form of a video game.`,
    TechStack: ["nextjs", "reactjs", "fluent UI", "colyseus"],
  },
  {
    Name: "ecosim-server",
    Repo: "https://github.com/ai-economy-simulator/ecosim-server",
    Owner: "Amish Mittal",
    github: "https://github.com/fliptrail",
    linkedin: "https://www.linkedin.com/in/amishmittal/",
    Description: `Game Server for AI Economy Simulator. Using AI program synthesis to predict macroeconomic factors in the form of a video game.`,
    TechStack: ["javascript", "colyseus", "mongoDB"],
  },
  {
    Name: "ecosim-ai",
    Repo: "https://github.com/ai-economy-simulator/ecosim-ai",
    Owner: "Amish Mittal",
    github: "https://github.com/fliptrail",
    linkedin: "https://www.linkedin.com/in/amishmittal/",
    Description: `AI Engine for AI Economy Simulator. Using AI program synthesis to predict macroeconomic factors in the form of a video game.`,
    TechStack: ["openAI", "GPT-4", "fastAPI", "economics"],
  },
  {
    Name: "Brewtopia",
    Repo: "https://github.com/Open-Source-Chandigarh/Brewtopia",
    Owner: "Varinder Singh",
    github: "https://github.com/Varinder-Dhillon0",
    linkedin: "https://www.linkedin.com/in/varinder-dhillon-5b8420263/",
    Description: `Brewtopia, a MERN stack-powered cafe management platform. Seamlessly add coffee and tea blends to your cart, with secure checkout and swift delivery. Join a tech-savvy community, leverage personalized recommendations, and elevate your cafe experience with our cutting-edge MERN architecture. Explore, code, and brew with Brewtopia!`,
    TechStack: ["reactjs", "mongoDB", "nodejs", "expressjs", "razorpay"],
  },
  {
    Name: "IGNYTE WEBSITE",
    Repo: "https://github.com/charu1603/Ignyte-website",
    Owner: "Chaitrali kakde",
    github: "https://github.com/charu1603",
    linkedin: "https://www.linkedin.com/in/chaitralikakde",
    Description: `We're a tight-knit community of developers, coders, and designers who are here to learn and teach, with a mission to spread the fire of knowledge and empower each other without any bounds!Get ready to join forces with other like-minded individuals in a fun, supportive environment! Let's set the tech world ablaze together!`,
    TechStack: ["reactjs", "tailwindCSS", "GSAP"],
  },
  {
    Name: "Vprofile",
    Repo: "https://github.com/Bhuvan028/Vprofile-devops-project",
    Owner: "Pasapala Bhuvanesh",
    github: "https://github.com/Bhuvan028",
    linkedin: "https://www.linkedin.com/in/bhuvanesh-p-147251211/",
    Description: `VProfile is a sophisticated full stack web application designed to streamline and enhance the process of managing user profiles. This project leverages various Amazon Web Services (AWS) components to ensure scalability, security, and reliability. VProfile incorporates AWS RDS, EC2, security groups, Elastic Beanstalk, Elastic Cache, and Amazon MQ to deliver a seamless user experience while adhering to industry best practices for web application development`,
    TechStack: [
      "linux",
      "git",
      "github",
      "AWS RDS",
      "AWS EC2",
      "security groups",
      "elastic beanstalk",
      "elastic cache",
      "Amazon MQ",
    ],
  },
  {
    Name: "RECB-Outii-Streamlining-Student-Tracking-with-QR-Technology",
    Repo: "https://github.com/arpitvermaofficial/RECB-Outii-Streamlining-Student-Tracking-with-QR-Technology",
    Owner: "Arpit Verma",
    github: "https://github.com/arpitvermaofficial/",
    linkedin: "https://www.linkedin.com/in/arpit-verma1/",
    Description: `Addressed record-keeping challenges by creating RECB_OUTII. Utilized QR codes to efficiently track student movements, capturing destination, timestamp, and crucial details. Implemented real-time SMS and Gmail alerts to notify wardens and parents instantly`,
    TechStack: ["flutter", "firebase", "dart"],
  },
  {
    Name: "GitAlert",
    Repo: "https://github.com/ItIsOHM/gfi-notifier/",
    Owner: "Rhythm Garg",
    github: "https://github.com/arpitvermaofficial/",
    linkedin: "https://www.linkedin.com/in/gargrhythm",
    Description: `This app allows a user to subscribe to a github repo and then get emails whenever a new issue is raised in that repo under the label "good first issue".`,
    TechStack: [
      "nodejs",
      "reactjs",
      "javascript",
      "mongoDB",
      "expressjs",
      "AWS EB",
      "resendAPI",
    ],
  },
  {
    Name: "Community-Website",
    Repo: "https://github.com/HITK-TECH-Community/Community-Website",
    Owner: "Kajol Kumari",
    github: "https://github.com/Kajol-Kumari",
    linkedin: "https://www.linkedin.com/in/kajol-kumari-73245b166/",
    Description: `We are a group of HITK students trying to fill the knowledge gap and help you find your place of discovery by sharing you the opportunities and resources at the right time!`,
    TechStack: ["reactjs", "nodejs", "mongoDB", "expressjs"],
  },
  {
    Name: "Sigma Events",
    Repo: "https://github.com/abhideepkumar/sigmaevents",
    Owner: "Abhideep Kumar",
    github: "https://github.com/abhideepkumar",
    linkedin: "https://www.linkedin.com/in/abhideepkumar/",
    Description: `Sigma Events" is a website that connects students and clubs for convenient event planning and participation. Instead of dealing with inconsistent event information in WhatsApp groups and repeating the same details for each event in Google Forms, this platform simplifies the process. `,
    TechStack: ["nextjs", "html", "css", "tailwindCSS", "mongoDB"],
  },
  {
    Name: "AR-Webstore",
    Repo: "https://github.com/ShwetKhatri2001/AR-Webstore",
    Owner: "Shwet Khatri",
    github: "https://github.com/ShwetKhatri2001",
    linkedin: "https://www.linkedin.com/in/shwet-khatri-4ab216196/",
    Description: `AR-Webstore is an e-commerce platform where a list of 3D products can be visualized in your own environment ( AR ) and can be ordered smoothly. `,
    TechStack: [
      "reactjs",
      "javascript",
      "html",
      "css",
      "google's 3D model viewer",
    ],
  },
  {
    Name: "Swetify-Music",
    Repo: "https://github.com/ShwetKhatri2001/Swetify-Music",
    Owner: "Shwet Khatri",
    github: "https://github.com/ShwetKhatri2001",
    linkedin: "https://www.linkedin.com/in/shwet-khatri-4ab216196/",
    Description: `Swetify-music is a web application to find and play your favourite music.It's is the golden opportunity to take the first step towards mastering web technologies and contributing to a real-world project via open-source! We have developed simply using HTML, CSS, and JavaScript which serve as the foundation for web development and provide an accessible entry point for newcomers to start their journey.`,
    TechStack: ["html", "css", "javascript", "jQuery"],
  },
  {
    Name: "FirstSpot",
    Repo: "https://github.com/jaysomani/firstspot",
    Owner: "Jayesh somani ",
    github: "https://github.com/jaysomani",
    linkedin: "https://www.linkedin.com/in/jayesh-somani/",
    Description: `Our website named, First spot is designed for the same, tourists can now explore new destinations where a guide will always be there to help you explore new paths and you can create your own paths as well. Planning a multi-city trip that not only helps you relax but also stirs in an extra element of excitement since you get to explore new destinations on the go.`,
    TechStack: ["html", "css", "javascript", "firebase"],
  },
  {
    Name: "Project Studio ",
    Repo: "https://github.com/BasirKhan418/Project-Studio.git",
    Owner: "Basir Khan",
    github: "https://github.com/BasirKhan418",
    linkedin:
      "https://www.linkedin.com/in/basir-khan-5aa62b258?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    Description: `Project Studio: The ultimate project management and developer collaboration tool for streamlining workflows, enhancing team productivity, and delivering outstanding results. Manage tasks, track code changes, and communicate seamlessly with your team, all in one place.`,
    TechStack: ["nextjs", "soket.io", "webrtc"],
  },
  {
    Name: "3D Artist",
    Repo: "https://github.com/sumeetghumare4/3D-Artist.git",
    Owner: "Sumeet Santosh Ghumare",
    github: "https://github.com/sumeetghumare4",
    linkedin: "https://www.linkedin.com/in/sumeet-ghumare24/",
    Description: `In the dynamic realm of 3D art creation, efficiency and precision are paramount. Our innovative project revolutionizes the traditional collaboration process between clients and 3D artists, addressing time-consuming iterations and potential misunderstandings. By leveraging state-of-the-art technologies, we've crafted a solution that redefines the industry standard.`,
    TechStack: ["openAI", "javascript", "python", "blender", "docker"],
  },
  {
    Name: "Enigma",
    Repo: "https://github.com/Arbtrage/Enigma",
    Owner: "Sayantan Naskar",
    github: "https://github.com/Arbtrage",
    linkedin: "https://www.linkedin.com/in/sayantan-naskar/",
    Description: `A Github App that executes and comments the code present in the Pull Request's diff .`,
    TechStack: [
      "nodejs",
      "webhooks",
      "probot",
      "github REST API",
      "Judge0 API",
      "rapid API",
    ],
  },
  {
    Name: "Moksh",
    Repo: "https://github.com/akshitagupta15june/Moksh",
    Owner: "Akshita Gupta ",
    github: "https://github.com/akshitagupta15june",
    linkedin: "https://www.linkedin.com/in/akshita-gupta-a4a895187",
    Description: `Moksh is a spiritual productivity tool designed for individuals who want to unlock their potential and achieve true balance in life. Our customized spiritual features help you take control of your work, focus on what truly matters, and rejuvenate your mind and spirit. By reading Bhagavad Gita, we become soul conscious and gain the strength to fight life's challenges. With Moksh, you can overcome the mental problems that arise from our body consciousness and find true happiness.`,
    TechStack: ["html", "css", "javascript", "maps API", "echo 3D"],
  },
  {
    Name: "Face-X",
    Repo: "https://github.com/akshitagupta15june/Face-X",
    Owner: "Akshita Gupta",
    github: "https://github.com/akshitagupta15june",
    linkedin: "https://www.linkedin.com/in/akshita-gupta-a4a895187",
    Description: `Face-X is a comprehensive repository of algorithms and operations related to facial recognition, which includes a range of functionalities from facial filters, image processing, face mask detection, and facial attendance systems to animated emotions and facial cartoonification. It encompasses all the possible operations that can be performed on the frontal human face, making it a versatile platform for facial-related tasks.`,
    TechStack: ["ML", "ANN", "CNN", "openCV", "python"],
  },
  {
    Name: "PetMe",
    Repo: "https://github.com/akshitagupta15june/PetMe",
    Owner: "Akshita Gupta",
    github: "https://github.com/akshitagupta15june",
    linkedin: "https://www.linkedin.com/in/akshita-gupta-a4a895187",
    Description: `PetMe is an all-in-one platform that allows animals to be adopted, donated to pet lovers, and provides emergency medical care to stray animals in need.`,
    TechStack: ["reactjs", "nodejs", "html", "css", "javascript"],
  },
  {
    Name: "Sangnet",
    Repo: "",
    Owner: "Sayak Saha",
    github: "https://github.com/sayakongit",
    linkedin: "https://linkedin.com/in/sayaksaha10",
    Description: `Sangnet swiftly connects compatible blood donors with recipients, prioritizing privacy and security. We simplify the process, enabling donors to help during emergencies and medical procedures, ultimately saving lives in our communities.`,
    TechStack: [
      "Django",
      "Django rest framework",
      "reactjs",
      "nextjs",
      "sqlite",
      "mapbox",
      "SDK",
      "auth0",
    ],
  },
];
