export const projectsDataNewer = [

    {
      Name: '',
      Repo: '',
      Owner: '',
      github: '',
      linkedin: '',
      Description: `Coming soon, Stay tuned...`,
      TechStack: [],
    },

  ];
  